*,
::after,
::before {
  box-sizing: border-box;

}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap');

html {
  font-size: 100%;
}

/*16px*/

:root {
  /* colors */
  --primary-50: #eff6ff;
  --primary-100: #dbeafe;
  --primary-200: #bfdbfe;
  --primary-300: #93c5fd;
  --primary-400: #60a5fa;
  --primary-500: #3b82f6;
  --primary-600: #2563eb;
  --primary-700: #1d4ed8;
  --primary-800: #1e40af;
  --primary-900: #1e3a8a;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bac4cf;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --blsdack: rgba(34, 34, 34, 0.74);
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: 'Roboto Condensed', Sans-Serif;
  --bodyFont: 'Cabin', Sans-Serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 4rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
  margin: 0;
  padding: 0;
  background: #fff;
  overflow-y: scroll;
}

.demotest {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 1rem;
  background-color: #b6d0e9;
}

p {
  /* margin-bottom: 1.5rem; */
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  /* margin-bottom: 1.38rem; */
  font-family: var(--headingFont);
  font-weight: 400;
  /* line-height: 1.3; */
  /* text-transform: capitalize; */
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}

a,
button {
  line-height: 1.15;
}

button:disabled {
  cursor: not-allowed;
}

ul {
  list-style-type: none;
  padding: 0;
}



.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* buttons */

.g-btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.g-btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

.light-btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-400);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.275rem 0.55rem;
  font-size: 14px;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.light-btn:hover {
  background: var(--primary-600);
  box-shadow: var(--shadow-3);
}

.g-btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}

.g-btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}

.g-btn-block {
  width: 100%;
}

.g-btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}

.g-btn-danger {
  cursor: pointer;
  color: #ffff;
  background: #da454a;
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;

  color: #ffff;
}

.g-btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}

.light-btn-danger {
  cursor: pointer;
  color: #ffff;
  background: #e46367;
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.275rem 0.55rem;
  font-size: 14px;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;

  color: #ffff;
}

.light-btn-danger:hover {
  background: #df474c;
  color: var(--white);
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}

.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}

/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
  transition: var(--transition);
}

.form:hover {
  box-shadow: var(--shadow-4);
}

.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-input,
.form-select,
.g-btn-block {
  height: 35px;
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}

.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: 0 auto;
}

/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}

.full-page {
  min-height: 100vh;
}

.coffee-info {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: var(--letterSpacing);
}

.coffee-info span {
  display: block;
}

.coffee-info a {
  color: var(--primary-500);
}

.global-title {
  /* background-color: var(--grey-100); */
  display: flex;
  /* justify-content:center; */
  /* align-items:center; */
  height: 3rem;
  /* border-radius:8px; */
  font-size: 30px;
  border-bottom: 2px solid grey;
  width: 100%;
  margin-bottom: 10px;
  /* font-weight:800px; */
}

.form-headings {
  display: flex;
}

.form-image {
  display: flex;
  justify-content: flex-start;
}

.g-asterisk {
  color: red;
  font-size: 20px;
}

@media screen and (min-width: 992px) {
  .coffee-info {
    text-align: left;
  }

  .coffee-info span {
    display: inline-block;
    margin-right: 0.5rem;
  }
}



/* 
table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 900px;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;

}

table tr:hover {
  background-color: #ddd;
}

table th, tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
} */